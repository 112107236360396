import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';
import { createOrgTagOption } from '../../../helpers/util';
import { AppContext } from '../../../context';
import OrgTagsContext from '../../../context/orgTagsContext';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import messages from './NewOrganizationDialog.messages';
import { default as validationMessages } from '../../../helpers/validation.messages';
import { CreateNewCustomerDto } from '../../../types/api/customers';
import { ApiResponse } from '../../../types/api/api';
import { Modal } from '../../../components/common/modal/Modal';
import { preventEventBubbling } from '../../common/PreventEventBubbling';
import { Checkbox, ComboboxOption, MODAL_MEDIUM } from '@planview/pv-uikit';
import { Combobox, Form, Input } from '@planview/pv-form';
import { Region } from '../../../types';
import { useForm } from '@mantine/form';
import { OrgHierarchySection } from './OrgHierarchySection';
import {
	CautionLabel,
	LeftAlignedWarningIcon,
	modalProps,
	ModalText,
	option,
	SectionHeader,
	TDUMSection,
	WarningDiv,
} from '../../../components/common/Dialog';
import { OrgHierarchyForm } from '../../../components/common/OrgHierarchyForm';
import isEmail from 'validator/lib/isEmail';

const OrgAdminHeaderContainer = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 1px;
	padding-bottom: ${spacingPx.small};
`;

type NewOrganizationDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
};

const OrgHierarchyConfirmationModal = ({ onConfirm, onCancel }: modalProps) => {
	const intl = useIntl();
	const [checkboxConfirm, setCheckboxConfirm] = useState(false);

	return (
		<Modal
			data-testid={'org-hierarchy-confirmation-modal'}
			size={MODAL_MEDIUM}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={intl.formatMessage(
				messages.orgHierarchyConfirmationButton,
			)}
			disableConfirm={!checkboxConfirm}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText={intl.formatMessage(messages.orgHierarchyHeader)}
		>
			<ModalText>
				<FormattedMessage {...messages.orgHierarchyMessage} />
			</ModalText>
			<ModalText>
				<FormattedMessage {...messages.orgHierarchyWarning} />
			</ModalText>
			<Checkbox
				defaultChecked={checkboxConfirm}
				label={intl.formatMessage(messages.orgHierarchyCheckboxLabel)}
				onChange={(value) => {
					setCheckboxConfirm(value);
				}}
			/>
		</Modal>
	);
};

const NewOrganizationDialog = (props: NewOrganizationDialogProps) => {
	const { onConfirm, onCancel } = props;
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const orgTagsContext = useContext(OrgTagsContext);
	const { topDownUserManagement: showTopDownComponents, enableUpBranchCode } =
		appContext.featureFlags;

	const availableRegionsOptions = React.useMemo(() => {
		const options: option[] = [];
		appContext.availableRegions.map((region) => {
			options.push({
				label: region,
				value: region,
			});
		});
		return options;
	}, [appContext]);

	const availableOrgTags =
		orgTagsContext.availableOrgTags.map(createOrgTagOption);

	const [isSaving, setSaving] = useState(false);

	const [
		showOrgHierachyConfirmationModal,
		setShowOrgHierachyConfirmationModal,
	] = useState(false);

	const orgForm = useForm({
		initialValues: {
			title: '',
			regulatoryRegion: {
				label: '',
				value: '',
			},
			topDownUserManagementEnabled: false,
			email: '',
			firstName: '',
			lastName: '',
			uofpBranchCode: '',
			orgTags: [] as ComboboxOption[],
		},
		validateInputOnBlur: true,
		validate: {
			title: (val) =>
				!val
					? intl.formatMessage(validationMessages.requiredField)
					: null,
			regulatoryRegion: (val) =>
				!val.value
					? intl.formatMessage(validationMessages.requiredField)
					: null,
			email: (val) =>
				!val
					? intl.formatMessage(validationMessages.enterEmail)
					: isEmail(val)
						? null
						: intl.formatMessage(validationMessages.invalidEmail),
			firstName: (val) =>
				!val
					? intl.formatMessage(validationMessages.enterFirstName)
					: null,
			lastName: (val) =>
				!val
					? intl.formatMessage(validationMessages.enterLastName)
					: null,
		},
	});

	const orgHierarchyForm = OrgHierarchyForm();

	const {
		title,
		regulatoryRegion,
		topDownUserManagementEnabled,
		email,
		firstName,
		lastName,
		uofpBranchCode,
		orgTags,
	} = orgForm.values;
	const { orgStructure, orgParent } = orgHierarchyForm.values;

	const sendNewOrgRequest = async () => {
		const sendParentOrgTitle = orgStructure.value === 'Child';

		const newCustomerDto: CreateNewCustomerDto = {
			title,
			regulatoryRegion:
				regulatoryRegion.value !== ''
					? (regulatoryRegion.value as Region)
					: 'US',
			topDownUserManagementEnabled,
			parentOrgTitle: sendParentOrgTitle ? orgParent.label : undefined,
			email,
			firstName,
			lastName,
			uofpBranchCode,
			orgTags: orgTags?.map((opt) => opt?.value as string),
		};

		const { success } = await requestWithErrorHandling<ApiResponse>({
			method: 'post',
			url: `/io/v1/admin/customer`,
			dataObj: newCustomerDto,
			appContext: appContext,
			intl,
			successMessage: messages.createOrgSuccess,
		});

		return success;
	};

	const save = async () => {
		setSaving(true);
		const success = await sendNewOrgRequest();
		setSaving(false);

		if (success && onConfirm) {
			onConfirm();
		}
	};

	const saveCheck = () => {
		const attemptingToSetParent =
			orgStructure.value === 'Child' && !!orgParent;

		if (attemptingToSetParent) {
			setShowOrgHierachyConfirmationModal(true);
		} else {
			void save();
		}
	};

	const formValid = () => {
		const validOrgHierarchySetting =
			orgStructure.value !== '' &&
			(orgStructure.value === 'Standalone' ||
				(orgStructure.value === 'Child' && orgParent.value !== ''));
		return (
			title !== '' &&
			validOrgHierarchySetting &&
			regulatoryRegion.value !== '' &&
			email !== '' &&
			firstName !== '' &&
			lastName !== ''
		);
	};

	return (
		<Modal
			id="new-organization-dialog"
			initialFocusId="org-title-input"
			onConfirm={saveCheck}
			onCancel={onCancel}
			confirmText={intl.formatMessage(messages.addButton)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			headerText={intl.formatMessage(messages.addHeader)}
			disableConfirm={
				isSaving || !formValid() || !!Object.keys(orgForm.errors).length
			}
			{...preventEventBubbling}
		>
			<Form label="New organization form">
				<SectionHeader>
					<Input
						label={intl.formatMessage(messages.organizationTitle)}
						id={'organization-name'}
						withAsterisk={true}
						{...orgForm.getInputProps('title')}
					/>
				</SectionHeader>

				<OrgHierarchySection orgForm={orgHierarchyForm} />

				<SectionHeader>
					<Combobox
						label={intl.formatMessage(messages.regRegion)}
						options={availableRegionsOptions}
						clearable={false}
						withAsterisk={true}
						{...orgForm.getInputProps('regulatoryRegion')}
					/>
				</SectionHeader>

				{enableUpBranchCode && (
					<SectionHeader>
						<Input
							label={intl.formatMessage(messages.branchCodeLabel)}
							id="uofp-branch-code"
							maxLength={50}
							{...orgForm.getInputProps('uofpBranchCode')}
						/>
					</SectionHeader>
				)}

				<SectionHeader>
					<Combobox
						label={intl.formatMessage(messages.orgTagLabel)}
						id="org-tags"
						options={availableOrgTags}
						clearable={true}
						withAsterisk={false}
						multiSelectable={true}
						{...orgForm.getInputProps('orgTags')}
					/>
				</SectionHeader>

				{showTopDownComponents ? (
					<TDUMSection>
						<FormattedMessage
							{...messages.topDownUserManagementHeader}
						/>
						<div>
							<Checkbox
								id="newOrg-TDUM-enabled"
								label={intl.formatMessage(
									messages.topDownUserManagementCheckBox,
								)}
								{...orgForm.getInputProps(
									'topDownUserManagementEnabled',
									{
										type: 'checkbox',
									},
								)}
								selected={topDownUserManagementEnabled}
							/>
						</div>
						<WarningDiv>
							<CautionLabel>
								<LeftAlignedWarningIcon />
								<FormattedMessage {...messages.cautionText} />
							</CautionLabel>
							<div>
								<FormattedMessage
									{...messages.topDownUserManagementWarningMessage}
								/>
							</div>
						</WarningDiv>
					</TDUMSection>
				) : null}

				<SectionHeader>
					<OrgAdminHeaderContainer>
						<FormattedMessage {...messages.orgAdminHeader} />
					</OrgAdminHeaderContainer>

					<Input
						label={intl.formatMessage(messages.firstName)}
						id={'admin-first-name'}
						withAsterisk={true}
						{...orgForm.getInputProps('firstName')}
					/>
					<Input
						label={intl.formatMessage(messages.lastName)}
						id={'admin-last-name'}
						withAsterisk={true}
						{...orgForm.getInputProps('lastName')}
					/>
					<Input
						label={intl.formatMessage(messages.email)}
						id={'admin-email'}
						type={'email'}
						withAsterisk={true}
						{...orgForm.getInputProps('email')}
					/>
				</SectionHeader>
			</Form>

			{showOrgHierachyConfirmationModal && (
				<OrgHierarchyConfirmationModal
					onConfirm={() => {
						void save();
						setShowOrgHierachyConfirmationModal(true);
					}}
					onCancel={() => {
						setShowOrgHierachyConfirmationModal(false);
					}}
				/>
			)}
		</Modal>
	);
};

export default NewOrganizationDialog;
