import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
	ADAPTIVEWORK,
	ADVISOR,
	COMMUNITY,
	COPILOT,
	E1_PRM,
	EA,
	HUB,
	LAUNCHPAD,
	LEANKIT,
	LOGBOOK,
	OKRS,
	PLANVIEW_ME,
	PPMPRO,
	PROJECTPLACE,
	ROADMAPS,
	SUCCESS,
	TEAMTAP,
	UP,
	VIZ,
} from '../../../assets';
import messages from './AppLogo.messages';
import { ApplicationName } from '../../../types';

type AppLogoDefinition = {
	logo: string;
	alt: MessageDescriptor;
	height: number;
	width: number;
};

type AppLogo = {
	[key in ApplicationName]: AppLogoDefinition;
};

// Height & width here are determined by the original svg viewbox
const productLogos = (scale: number): AppLogo => ({
	ADAPTIVEWORK: {
		logo: ADAPTIVEWORK,
		alt: messages.adaptiveWork,
		height: Math.round(176.9 * scale),
		width: Math.round(724.6 * scale),
	},
	ADVISOR: {
		logo: ADVISOR,
		alt: messages.advisor,
		height: Math.round(152.6 * scale),
		width: Math.round(524.5 * scale),
	},
	COMMUNITY: {
		logo: COMMUNITY,
		alt: messages.community,
		height: Math.round(144.88 * scale),
		width: Math.round(844.17 * scale),
	},
	COPILOT_ADMIN: {
		logo: COPILOT,
		alt: messages.copilotAdmin,
		height: Math.round(175.9 * scale),
		width: Math.round(539.4 * scale),
	},
	E1_PRM: {
		logo: E1_PRM,
		alt: messages.e1PRM,
		height: Math.round(176.5 * scale),
		width: Math.round(543.9 * scale),
	},
	EA: {
		logo: EA,
		alt: messages.ea,
		height: Math.round(155.1 * scale),
		width: Math.round(530.3 * scale),
	},
	HUB: {
		logo: HUB,
		alt: messages.hub,
		height: Math.round(156.6 * scale),
		width: Math.round(526.6 * scale),
	},
	LAUNCHPAD: {
		logo: LAUNCHPAD,
		alt: messages.launchpad,
		height: Math.round(175.9 * scale),
		width: Math.round(580.2 * scale),
	},
	LEANKIT: {
		logo: LEANKIT,
		alt: messages.leanKit,
		height: Math.round(180.5 * scale),
		width: Math.round(581.4 * scale),
	},
	LOGBOOK: {
		logo: LOGBOOK,
		alt: messages.logBook,
		height: Math.round(154.83 * scale),
		width: Math.round(507.92 * scale),
	},
	LOGBOOK_REPORTING: {
		logo: LOGBOOK,
		alt: messages.logBook,
		height: Math.round(154.83 * scale),
		width: Math.round(507.92 * scale),
	},
	OKRS: {
		logo: OKRS,
		alt: messages.okrs,
		height: Math.round(154.4 * scale),
		width: Math.round(535.4 * scale),
	},
	OKRS_REPORTING: {
		logo: OKRS,
		alt: messages.okrs,
		height: Math.round(154.4 * scale),
		width: Math.round(535.4 * scale),
	},
	PLANVIEW_ME: {
		logo: PLANVIEW_ME,
		alt: messages.planviewMe,
		height: Math.round(70 * scale),
		width: Math.round(638 * scale),
	},
	PPMPRO: {
		logo: PPMPRO,
		alt: messages.ppmPro,
		height: Math.round(153.6 * scale),
		width: Math.round(529.7 * scale),
	},
	PROJECTPLACE: {
		logo: PROJECTPLACE,
		alt: messages.projectPlace,
		height: Math.round(172.3 * scale),
		width: Math.round(649.4 * scale),
	},
	ROADMAPS: {
		logo: ROADMAPS,
		alt: messages.roadmaps,
		height: Math.round(175.9 * scale),
		width: Math.round(580.2 * scale),
	},
	SUCCESS: {
		logo: SUCCESS,
		alt: messages.success,
		height: Math.round(129.08 * scale),
		width: Math.round(844.17 * scale),
	},
	TEAMTAP: {
		logo: TEAMTAP,
		alt: messages.teamtap,
		height: Math.round(180.4 * scale),
		width: Math.round(527.2 * scale),
	},
	UP: {
		logo: UP,
		alt: messages.up,
		height: Math.round(127.49 * scale),
		width: Math.round(620.02 * scale),
	},
	VIZ: {
		logo: VIZ,
		alt: messages.viz,
		height: Math.round(175.9 * scale),
		width: Math.round(595.5 * scale),
	},
});

type AppLogoProps = {
	app?: ApplicationName;
	scale?: number;
};

const AppLogo = (props: AppLogoProps) => {
	const { app, scale = 0.25 } = props;
	const intl = useIntl();
	if (!app) {
		return;
	}

	const { logo, alt, height, width } = productLogos(scale)[app];
	return (
		<img
			src={logo}
			alt={intl.formatMessage(alt)}
			height={height}
			width={width}
		/>
	);
};

export default AppLogo;
