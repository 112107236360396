import React, { useContext, useMemo } from 'react';
import { AppContext, DovetailContext, UserContext } from '../../../../context';
import DovetailComponent, {
	AdditionalDovetailProps,
	getDovetailLocale,
} from '../../../../components/common/DovetailComponent';
import {
	DovetailComponentType,
	DovetailLocale,
} from '../../../../types/dovetail/dovetail';
import { ArrowLeft } from '@planview/pv-icons';
import { IntlShape, useIntl } from 'react-intl';
import messages from './index.messages';
import { default as globalStateMessages } from '../../../../context/GlobalState.messages';
import { ButtonEmpty } from '@planview/pv-uikit';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';
import { border, color, sizePx, spacingPx, text } from '@planview/pv-utilities';
import { Toolbar } from '@planview/pv-toolbar';
import { settingPageContainerWidth } from '../SettingsPage';
import ErrorBoundary from '../../../../components/common/ErrorBoundary';
import { TenantGroup } from '../../../../types';
import './DovetailContainer.css';

type ServiceSettingsContainerParams = {
	tenantGroupId: string;
};

const StyleContainer = styled.div<{ $fullPage: boolean }>`
	overflow: auto;
	${({ $fullPage }) =>
		!$fullPage &&
		`
    	width: ${settingPageContainerWidth};
		margin-left: auto;
		margin-right: auto;
		margin-top: ${spacingPx.medium};
	`}
	background: white;
	max-width: 100%;
	display: flex;
	height: 100%;
`;

const BackButtonToolbar = styled(Toolbar)`
	${text.regular}
	background-color: #ffffff;
	min-height: ${sizePx.small};
	height: ${sizePx.small};
	${border.light()};
	border-width: 0 0 1px 0;
	box-shadow: none;
`;

const DovetailComponentContainer = styled.div<{ $fullPage: boolean }>`
	${({ $fullPage }) => !$fullPage && `padding: ${spacingPx.large};`}
	flex-grow: 1;
	overflow-y: auto;
	margin-left: auto;
	margin-right: auto;
	background-color: #ffffff;
	width: ${settingPageContainerWidth};
	max-width: 100%;
	display: flex;
`;

type EnvironmentTypeProps = {
	label: string;
	color: string;
};

/**
 * Get the props necesasry for a Dovetail component to render the environment's Chip.
 */
const getEnvironmentTypeProps = (
	tenantGroup: TenantGroup,
	intl: IntlShape,
): EnvironmentTypeProps => {
	const { sandbox, primary, title } = tenantGroup;

	if (!sandbox) {
		return {
			label: intl.formatMessage(globalStateMessages.production),
			color: `${color.primary500}`,
		};
	} else if (primary) {
		return {
			label: intl.formatMessage(globalStateMessages.primarySandbox),
			color: `${color.warning500}`,
		};
	} else {
		return {
			label: title,
			color: `${color.warning500}`,
		};
	}
};

type ServiceSettingsContainerProps = {
	className: string;
	componentName: string;
	componentType: DovetailComponentType;
	distFile: string;
	fullPage?: boolean;
	/**
	 * Indicates user_locale / userLocale properties are passed to dovetail
	 * Ideally, dovetail components will pickup locale from browser and not our hardcoded values
	 */
	useLegacyLocale: boolean;
};

const ServiceSettingsContainer = (props: ServiceSettingsContainerProps) => {
	const path = useResolvedPath('').pathname;
	const { tenantGroupId = '' } = useParams<ServiceSettingsContainerParams>();
	const {
		className,
		componentName,
		componentType,
		distFile,
		useLegacyLocale,
	} = props;
	const lang = navigator.language;
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const { dovetailEnvironment, dovetailUrl } = useContext(DovetailContext);
	const { user } = userContext;
	const userId = user.id;
	const intl = useIntl();
	const navigate = useNavigate();
	const tenantGroup = appContext.tenantGroupMap.get(tenantGroupId)!;

	const dovetailProps: AdditionalDovetailProps = useMemo(() => {
		// Watch for OKRs and LogBook to remove the need to have user_locale
		const userLocale = useLegacyLocale
			? {
					date_format: 'MM/DD/YYYY',
					locale: getDovetailLocale(lang) as DovetailLocale,
					start_of_week: 0,
				}
			: undefined;

		return {
			environment: dovetailEnvironment,
			user_id: userId,
			userId,
			user_locale: userLocale,
			baseUrl: path,
			environmentType: getEnvironmentTypeProps(tenantGroup, intl),
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dovetailEnvironment, userId, lang, tenantGroup, tenantGroupId, intl]);

	const componentUrl = `${dovetailUrl}/dist/${distFile}`;
	return (
		<>
			<BackButtonToolbar label="breadcrumb-toolbar">
				<ButtonEmpty
					icon={<ArrowLeft />}
					onClick={() =>
						navigate(`/admin/settings/services`, {
							state: { redirect: true },
						})
					}
				>
					{intl.formatMessage(messages.backButton)}
				</ButtonEmpty>
			</BackButtonToolbar>
			<StyleContainer $fullPage={!!props.fullPage} className={className}>
				<DovetailComponentContainer $fullPage={!!props.fullPage}>
					<ErrorBoundary>
						<DovetailComponent
							name={componentName}
							type={componentType}
							element="div"
							url={componentUrl}
							tenantGroupId={tenantGroupId}
							props={dovetailProps}
						/>
					</ErrorBoundary>
				</DovetailComponentContainer>
			</StyleContainer>
		</>
	);
};

export default ServiceSettingsContainer;
